import React from "react";

const SignIn = ({ template: Template, title, children }) => {

    return (
        <Template title={title}>
            <div className="col-md-3"></div>

            <div className="text-center col-md-6">
                <div className="card">
                    <p></p>
                    <img className="mx-auto mt-3" src='/logo.png' alt="Care Access" width="190" />
                    <h2 className="h3 mb-3 font-weight-normal">Signature Tool</h2>
                    <div className="sign-in card-body mt-4 mb-5 mx-auto">
                        <div id="google-sign-in"></div>
                    </div>
                    <div className="alert alert-light mt-4 mb-0 font-italic" role="alert">
                        This tool will work for <mark><strong>@careaccess.com</strong></mark> emails only!
                    </div>
                </div>
            </div>

            <div className="col-md-3"></div>
        </Template>
    )
}

export default SignIn;