import React, {useEffect, useRef, useState, useContext } from "react";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import { AuthContext } from "../components/GAPI";
import { Tooltip } from 'react-tooltip';
import { GetCareSignature } from "../lib/CareSignature";

const Home = () => {

    const { authUser } = useContext(AuthContext)

    const [loading, setLoading] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [fullName, setFullName] = useState('')
    const [roleTitle, setRoleTitle] = useState('')
    const [credentials, setCredentials] = useState('')
    const [timezone, setTimezone] = useState('')
    const [phone, setPhone] = useState('')
    const [fax, setFax] = useState('')
    const [meetingLink, setMeetingLink] = useState('')
    const [usePhoto, setUsePhoto] = useState(true)
    const [signoff, setSignoff] = useState('')
    const mountedRef = useRef(true)
    const [infoModal, setInfoModal] = useState(null)
    const [success, setSuccess] = useState(true)
    const [successModal, setSuccessModal] = useState(null)
    const [profileImageUrl, setProfileImageUrl] = useState('')

    const signOut = () => {
        window.gapi.auth2.getAuthInstance().signOut()
    }

    const showInstructions = () => {
        if (infoModal) {
            infoModal.show()
        }
    }

    const grabValueFromSignature = (signatureText, tag, attrName, attrValue, ) => {
        let searchText = attrName + '="'+attrValue+'">';

        let searchTextStart = signatureText.indexOf(searchText);
        if (searchTextStart > 0) {
            let searchTextStartIndex = searchTextStart + searchText.length;
            let searchTextEndIndex = signatureText.indexOf('</'+ tag + '>', searchTextStartIndex);
            // return value
            return signatureText.substring(searchTextStartIndex, searchTextEndIndex);
        }
        return ''
    }

    const loadUserSettings = (loggedInUser) => {
        const request = window.gapi.client.request({
            'path': 'https://gmail.googleapis.com/gmail/v1/users/me/settings/sendAs/' + loggedInUser.getEmail() ,
            'method': 'GET'
        })

        request.execute(function(resp) {
            if (resp.error) {
                console.log("we found an error")
            } else {
                let sigFullName = loggedInUser.getGivenName() + ' ' + loggedInUser.getFamilyName()
                setFullName(sigFullName)
                document.getElementById('displayname').value = sigFullName

                // Let's parse the signature only if it's a Care Access one set by this tool
                if (resp.signature.includes('color:rgb(5,71,74)') && resp.signature.includes('color:#05474a;padding:0 0.25rem')) {

                    // Start by turning the photo off if user is not using one
                    if (!resp.signature.includes('https://lh3.googleusercontent.com')) {
                        setUsePhoto(false)
                        document.getElementById('usePhotoSwitch').checked = false
                    }

                    setCredentials(grabValueFromSignature(resp.signature, 'span', 'alt', 'credentials'))
                    setRoleTitle(grabValueFromSignature(resp.signature, 'span', 'alt', 'role'))
                    setTimezone(grabValueFromSignature(resp.signature, 'span', 'alt', 'timezone'))
                    setPhone(grabValueFromSignature(resp.signature, 'span', 'alt', 'phone'))
                    setFax(grabValueFromSignature(resp.signature, 'span', 'alt', 'fax'))
                    setMeetingLink(grabValueFromSignature(resp.signature, 'a', 'href', 'schedule'))
                    setSignoff(grabValueFromSignature(resp.signature, 'span', 'alt', 'signoff'))
                }
            }
        })
    }

    const copySignatureHTML = (e) => {
        if (document.getElementById('signature-details').checkValidity()) {
            e.preventDefault()
            const photoUrl = usePhoto ? currentUser.getImageUrl() : null
            const htmlSignature = GetCareSignature(fullName, roleTitle, phone, fax, currentUser.getEmail(), timezone, meetingLink, credentials, photoUrl, signoff)

            try {
                const blobInput = new Blob([htmlSignature], {type: 'text/html'});
                const clipboardItemInput = new window.ClipboardItem({'text/html' : blobInput});
                navigator.clipboard.write([clipboardItemInput]);
                successModal.show()
                console.log(htmlSignature)
            } catch(e) {
                // Handle error with user feedback - "Copy failed!" kind of thing
                console.log(e);
            }
        }
    }

    useEffect(() => {
        if (!currentUser) {
            setLoading(true)
            setCurrentUser(authUser.user)
            let imageUrl = authUser.user.getImageUrl()
            if (imageUrl) {
                imageUrl = imageUrl.replace('=s96', '=s160')
            } else {
                imageUrl = 'imageError'
            }
            setProfileImageUrl(imageUrl)
            setFullName(authUser.user.getGivenName() + ' ' + authUser.user.getFamilyName())
            loadUserSettings(authUser.user)
            setLoading(false)
        } else {
            let instructionsModal = new window.coreui.Modal(document.getElementById('infoModal'), {
                keyboard: false
            })
            let successDialog = new window.coreui.Modal(document.getElementById('successModal'), {
                keyboard: false
            })
            instructionsModal.show()
            setInfoModal(instructionsModal)
            setSuccessModal(successDialog)
            setFullName(currentUser.getName())
        }

        return () => {
            mountedRef.current = false
        }
    }, [currentUser, authUser]);

    return (
        <>
            {
                currentUser?(
                    <>
                        <div className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show" id="sidebar">
                            <div className="c-sidebar-brand d-md-down-none">
                                <img src='/one-color_mark.png' alt="Care Access" width="50" />
                                <h2 className="h4 mt-2 ml-3 font-weight-normal">Signature Details</h2>
                            </div>
                            <div className="c-body">
                                <div className="c-main">
                                    <form action="#0" method="POST" id="signature-details">
                                <div className="form-group">
                                    <label htmlFor="displayname">Full Name *
                                        <span data-tip data-for="fullnameTip">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                 strokeLinejoin="round" className="feather feather-info">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                                <line x1="12" y1="8" x2="12" y2="8"></line>
                                            </svg>
                                        </span>
                                        <Tooltip id="fullnameTip" place="right" effect="solid">
                                            You have the option to change the way your<br/>
                                            name shows up when someone receives an email <br/>
                                            from you. If there are no changes or corrections <br/>
                                            to your name, you can leave it as is.
                                        </Tooltip>
                                    </label>
                                    <input type="text" className="form-control" id="displayname"
                                           onChange={ e => setFullName(e.target.value) } value={ fullName || ''}
                                           placeholder="Enter your full name here" required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="roletitle">Role Title *
                                        <span data-tip data-for="roletitleTip">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                 strokeLinejoin="round" className="feather feather-info">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                                <line x1="12" y1="8" x2="12" y2="8"></line>
                                            </svg>
                                        </span>
                                        <Tooltip id="roletitleTip" place="right" effect="solid">
                                            Please make sure you use the exact role title<br/>
                                            that has been approved by your supervisor.
                                        </Tooltip>
                                    </label>
                                    <input type="text" className="form-control" id="roletitle" value={roleTitle || ''}
                                           onChange={ e => setRoleTitle(e.target.value) }
                                           placeholder="Enter your role title at CARE (no abbreviations please)" required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="timezone">Timezone
                                        <span data-tip data-for="timezoneTip">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                 strokeLinejoin="round" className="feather feather-info">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                                <line x1="12" y1="8" x2="12" y2="8"></line>
                                            </svg>
                                        </span>
                                        <Tooltip id="timezoneTip" place="right" effect="solid">
                                            This field is optional. If you wish to let the others<br/>
                                            know of your timezone and/or location you could fill this out.<br/>
                                            Example: "EST - Remote" or "EST - Boston, MA"
                                        </Tooltip>
                                    </label>
                                    <input type="text" className="form-control" id="timezone"
                                           onChange={ e => setTimezone(e.target.value) } value={timezone || ''}
                                           placeholder="E.g. EST - Remote | EST - Boston, MA | ..." />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="credentials">Academic Titles/Credentials
                                        <span data-tip data-for="credentialsTip">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                 strokeLinejoin="round" className="feather feather-info">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                                <line x1="12" y1="8" x2="12" y2="8"></line>
                                            </svg>
                                        </span>
                                        <Tooltip id="credentialsTip" place="right" effect="solid">
                                            This field is optional and not required. If you do have credentials<br/>
                                            that you wish to use, the company has a policy where one can only use<br/>
                                            credentials acquired within the US. Degrees or titles acquired outside<br/>
                                            of the states should not be included in this field.<br/>
                                            If you fill in this field, your Full Name (Display Name) will have the<br/>
                                            credentials appended automatically.<br/>
                                            (.e.g. "John Doe" would become "John Doe, PhD")
                                        </Tooltip>
                                    </label>
                                    <input type="text" className="form-control" id="credentials"
                                           onChange={ e => setCredentials(e.target.value) } value={credentials || ''}
                                           placeholder="E.g. MD, Phd, MBA etc" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Phone Number
                                        <span data-tip data-for="phoneTip">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                 strokeLinejoin="round" className="feather feather-info">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                                <line x1="12" y1="8" x2="12" y2="8"></line>
                                            </svg>
                                        </span>
                                        <Tooltip id="phoneTip" place="right" effect="solid">
                                            When entering your phone number,<br/>
                                            please use the format "(area code) xxx-xxxx"
                                        </Tooltip>
                                    </label>
                                    <input type="text" className="form-control" id="phone"
                                           onChange={ e => setPhone(e.target.value) }  value={ phone || ''}
                                           placeholder="Enter phone in (xxx) yyy-zzzz format" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="fax">Fax Number
                                        <span data-tip data-for="faxTip">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                 strokeLinejoin="round" className="feather feather-info">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                                <line x1="12" y1="8" x2="12" y2="8"></line>
                                            </svg>
                                        </span>
                                        <Tooltip id="faxTip" place="right" effect="solid">
                                            You may leave this blank or use your or your team's fax number <br/>
                                            as assigned by the IT team. When entering your fax number,<br/>
                                            please use the format "(area code)-xxx-xxxx"
                                        </Tooltip>
                                    </label>
                                    <input type="text" className="form-control" id="fax"
                                           onChange={ e => setFax(e.target.value) }  value={ fax || ''}
                                           placeholder="Enter fax number in 123-456-7890 format" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="meetingLink">Schedule Meeting Link
                                        <span data-tip data-for="meetingLinkTip">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                 strokeLinejoin="round" className="feather feather-info">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                                <line x1="12" y1="8" x2="12" y2="8"></line>
                                            </svg>
                                        </span>
                                        <Tooltip id="meetingLinkTip" place="right" effect="solid">
                                            This field is optional. It is included in <br/>
                                            this tool simply to make it easier for you to add <br/>
                                            a meeting link such as Calendly or a similar platform <br/>
                                            to your signature. For consistency this tool will <br />
                                            display the label "Schedule a meeting" only.
                                        </Tooltip>
                                    </label>
                                    <input type="text" className="form-control" id="meetingLink"
                                           onChange={ e => setMeetingLink(e.target.value) } value={meetingLink || ''}
                                           placeholder="E.g. https://calendly.com/______" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="signoff">Email Sign-off
                                        <span data-tip data-for="signoffTip">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                 strokeLinejoin="round" className="feather feather-info">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                                <line x1="12" y1="8" x2="12" y2="8"></line>
                                            </svg>
                                        </span>
                                        <Tooltip id="signoffTip" place="right" effect="solid">
                                            This field is optional. It is included in this <br/>
                                            tool simply to make it easier for you to add <br/>
                                            your favorite sign-off (e.g. Best, Regards, etc.) <br/>
                                            right before your signature.
                                        </Tooltip>
                                    </label>
                                    <input type="text" className="form-control" id="signoff"
                                           onChange={ e => setSignoff(e.target.value) } value={signoff || ''}
                                           placeholder="E.g. Best, | Cheers, | Best Wishes, | ..." />
                                </div>
                                <div className="form-group">
                                    <label className="c-switch c-switch-label c-switch-pill c-switch-success">
                                        <input type="checkbox" className="c-switch-input" checked={usePhoto} id="usePhotoSwitch"
                                               onChange={ e => setUsePhoto(!usePhoto) }
                                        />
                                            <span className="c-switch-slider" data-checked="&#x2713;"
                                                  data-unchecked="&#x2715;"></span>
                                    </label>
                                    <label htmlFor="usePhotoSwitch">Use photo in signature
                                        <span data-tip data-for="photoTip">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                 strokeLinejoin="round" className="feather feather-info">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                                <line x1="12" y1="8" x2="12" y2="8"></line>
                                            </svg>
                                        </span>
                                        <Tooltip id="photoTip" place="right" effect="solid">
                                            By default, this tool will grab your Gmail profile photo. <br/>
                                            To remove your photo, simply uncheck this switch and<br/>
                                            your photo will be removed.<br />
                                            Please, <strong>uncheck</strong> this if your photo is the company logo.
                                        </Tooltip>
                                    </label>
                                </div>
                                <button onClick={(e) => {
                                    copySignatureHTML(e);
                                }} type="submit" className="btn btn-success btn-signature btn-lg btn-block">
                                    <>COPY SIGNATURE CONTENTS</>
                                </button>
                            </form>
                                </div>
                            </div>
                        </div>
                        <div className="c-wrapper">
                            <header className="c-header c-header-light c-header-fixed">
                                <div className="see-instructions">
                                    <button onClick={showInstructions} type="button" className="btn btn-sm btn-outline-info">See Instructions</button>
                                </div>
                                <div className="mfe-auto">
                                    <button className="c-header-toggler c-class-toggler mfs-3 d-md-down-none" data-target="#sidebar" data-class="c-sidebar-lg-show">
                                        <i className="mt-3 fa fa-bars" />
                                    </button>
                                </div>
                                { currentUser &&
                                    <>
                                        <div className="userProfile">
                                            <Link to="/" onClick={(e) => {
                                                e.preventDefault();
                                                signOut();
                                            }}>
                                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                                Sign Out
                                            </Link>
                                            <div className="c-avatar">
                                                <img src={profileImageUrl} width="50px" height="50px" alt={fullName} />
                                            </div>
                                        </div>
                                    </>
                                }
                            </header>
                            <div className="c-body">
                                <main className="c-main">
                                   <div className="compose-dialog">
                                       <div className="top-bar"><div className="content">New Message</div></div>
                                       <div className="message">
                                           <p>Dear {currentUser.getGivenName()},</p>
                                           <p>
                                               The form to the left of this screen allows you to update your email signature. Your email
                                               signature represents Care Access and its brand. Therefore, it is very important that you
                                               update your signature using this tool. Once done, click "COPY SIGNATURE CONTENTS" to copy your new signature.
                                               <mark>If you do not see the button, please scroll the left form down (for smaller screens it might be further down below the bottom of your screen).</mark>
                                           </p>
                                           <p>
                                               The bottom of this template  is a live preview of how your signature will look after the update.
                                           </p>
                                           <p>
                                               For any questions, feel free to email The Care Access Marketing Team at <a href="mailto:marketing@careaccess.com">marketing@careaccess.com</a>
                                           </p>
                                           <p></p>
                                           <p className="signoff">
                                               <br/>
                                               { signoff }
                                           </p>
                                           <p dir="ltr" className="cellInner large" id="fullname">
                                               <span>{ fullName }{ (credentials !== '') ? ', ' + credentials : '' }</span>
                                           </p>
                                           <p dir="ltr" className="cellInner" id="role">
                                               <span>{ roleTitle }</span>
                                           </p>
                                           <div className="signature" id="signature-content">
                                               <div dir="ltr" align="left">
                                                   <table>
                                                       <tbody>
                                                       <tr>
                                                       { usePhoto &&
                                                           <td width="83px" className="sig-border-top" >
                                                               <img src={ profileImageUrl } width="83" height="83" />
                                                           </td>
                                                       }
                                                           <td className="sig-border-top leftpadding">
                                                               <p dir="ltr" className="cellInner">
                                                               { phone &&
                                                                   <a href={"tel:"+phone}>
                                                                       <span className="phoneIcon icon">&nbsp;</span>
                                                                       <span className="signature-link">{phone}</span>
                                                                   </a>
                                                                }
                                                               </p>
                                                               { fax &&
                                                               <p dir="ltr" className="cellInner">
                                                                   <span className="faxIcon icon">&nbsp;</span>
                                                                   <span>{ fax }</span>
                                                               </p>
                                                               }
                                                               { timezone && !meetingLink  &&
                                                               <p dir="ltr" className="cellInner">
                                                                   <span className="locationIcon icon">&nbsp;</span>
                                                                   <span>{timezone}</span>
                                                               </p>
                                                               }
                                                               <p dir="ltr" className="cellInner">
                                                                   <a href={"mailto:" + currentUser.getEmail() }>
                                                                       <span className="emailIcon icon">&nbsp;</span>
                                                                       <span className="signature-link">{ currentUser.getEmail() }</span>
                                                                   </a>
                                                               </p>
                                                               {(!fax || !phone ||  (!meetingLink && !timezone)) &&
                                                               <p dir="ltr" className="cellInner">
                                                                   <a href="https://www.careaccess.com">
                                                                       <span className="laptopIcon icon">&nbsp;</span>
                                                                       <span className="signature-link">www.careaccess.com</span>
                                                                   </a>
                                                               </p>
                                                               }
                                                               { meetingLink &&
                                                               <p dir="ltr" className="cellInner">
                                                                   <a href={ meetingLink }>
                                                                       <span className="calendarIcon icon">&nbsp;</span>
                                                                       <span className="signature-link">Schedule a meeting { (timezone !== '') ? ' (' + timezone + ')' : '' }</span></a>
                                                               </p>
                                                               }
                                                               { ((+Boolean(fax) + + +Boolean(phone) + +Boolean(timezone) + +Boolean(meetingLink)) < 2) &&
                                                               <p dir="ltr" className="cellInner quote">
                                                                   <span>~ Accelerating the Future of Medicine ~</span>
                                                               </p>
                                                               }
                                                           </td>
                                                       </tr>
                                                       </tbody>
                                                   </table>
                                                   <table>
                                                       <tbody>
                                                       <tr>
                                                           <td className="signature-footer">
                                                               <table>
                                                                   <tbody>
                                                                       <tr>
                                                                           <td>
                                                                               <p dir="ltr">
                                                                                   <a href="https://careaccess.com">
                                                                                       <span className="emptySpace1"> </span>
                                                                                       <span className="care-access">CareAccess.com</span>
                                                                                   </a>
                                                                                   <span className="emptySpace2">&nbsp; &nbsp; &nbsp; &nbsp; </span>
                                                                               </p>
                                                                           </td>
                                                                           <td className='social-links'>
                                                                               <p>
                                                                                   <a href="https://www.linkedin.com/company/careaccess">
                                                                                       <span className="social-link">
                                                                                           LinkedIn
                                                                                       </span>
                                                                                   </a>
                                                                                   <span className="separator"> | </span>
                                                                                   <a href="https://www.facebook.com/CareAccessRes/">
                                                                                       <span className="social-link">
                                                                                           Facebook
                                                                                       </span>
                                                                                   </a>
                                                                                   <span className="separator"> | </span>
                                                                                   <a href="https://www.youtube.com/channel/UC_RFAvqf86t_I8lgczCKjjA">
                                                                                       <span className="social-link">
                                                                                           YouTube
                                                                                       </span>
                                                                                   </a>
                                                                                   <span className="separator"> | </span>
                                                                                   <a href="https://twitter.com/careaccessres?lang=en">
                                                                                       <span className="social-link">
                                                                                           Twitter
                                                                                       </span>
                                                                                   </a>
                                                                               </p>

                                                                           </td>
                                                                       </tr>
                                                                   </tbody>
                                                               </table>
                                                           </td>
                                                       </tr>
                                                       <tr className="height-0">
                                                           <td>
                                                               <p dir="ltr" className="cellInner">
                                                                   <a href="https://careaccess.com">
                                                                       <span className="logo-wrapper">
                                                                           <span>
                                                                               <img src="https://careaccess.com/email-care-logo.png" width="364" height="48"  />
                                                                           </span>
                                                                       </span>
                                                                   </a>
                                                               </p>
                                                           </td>
                                                       </tr>
                                                       </tbody>
                                                   </table>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                                </main>
                            </div>
                        </div>

                        <div className="modal fade" id="infoModal" tabIndex="-1" role="dialog"
                             aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-scrollable modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalScrollableTitle">INSTRUCTIONS - Please do read through them!</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body p-5">
                                        <div className="alert alert-danger d-lg-block d-xl-none" role="alert">
                                            <strong>NO SUPPORT FOR SMALL SCREENS:</strong><br/>
                                            This tool has not been optimized for devices with smaller screens. You must use it from a laptop or desktop.
                                            Or otherwise, please maximize the current browser window if that is currently opened in a smaller size.
                                        </div>
                                        { profileImageUrl === 'imageError' &&
                                            <div className="alert alert-danger mb-5" role="alert">
                                                <strong>ERROR: CANNOT RETRIEVE PROFILE PHOTO</strong><br/>
                                                Due to a Google caching error, this tool is being unable to retrieve your profile picture, however the fix is quite simple:
                                                <ul className="mb-4">
                                                    <li>log out of this tool</li>
                                                    <li>log out of your Care Access Gmail</li>
                                                    <li>and then re-login into both apps.</li>
                                                </ul>
                                                When you come back here, you will not see this message again. If you do, please let us know at <a href="mailto:marketing@careaccess.com">
                                                marketing@careaccess.com
                                            </a>
                                            </div>
                                        }

                                        <a className="c-avatar mb-1" href={"https://myaccount.google.com/profile/photo/edit?authuser=" + currentUser.getEmail() } target="_blank"><img src={profileImageUrl} width="160" /></a>
                                        <div className="text-center mb-3"><a className="font-italic text-muted" href={"https://myaccount.google.com/profile/photo/edit?authuser=" + currentUser.getEmail() } target="_blank">Update Photo</a></div>
                                        <p>Hello {currentUser.getGivenName()},</p>
                                        <p>Before using this tool, <mark><strong>please read the following</strong></mark>:</p>
                                        <h4>Profile Picture</h4>
                                        <p>
                                            It is very important that you go over the <a data-toggle="collapse" href="#collapseHeadshotGuidelines" role="button" aria-expanded="false" aria-controls="collapseHeadshotGuidelines" >Care Access Headshot Guidelines</a> and adjust your profile picture if needed.
                                        </p>
                                        <div className="collapse" id="collapseHeadshotGuidelines">
                                            <div className="card card-body bg-light">
                                                <h3>Care Access Employee Headshot Guidelines</h3>
                                                <p>As we use Gmail and other tools to communicate on behalf of (and representing) Care Access, it is very important that we maintain a professional standard. The goal of your Care Access headshot is to convey professionalism through the setting, the outfit you wear, and your facial expression. In order to meet this requirement, all headshot photos that are used in any representation of Care Access must adhere to the following guidelines:</p>
                                                <h5>Required</h5>
                                                <ul>
                                                    <li>The goal of the headshot is to convey professionalism through the setting, the outfit you wear, your posture, and your facial expression, therefore:
                                                        <ul>
                                                            <li>Headshot should be only of you in a standing or seated position.                                                                                        </li>
                                                            <li>Use a recent photo of yourself.                                                                                                                         </li>
                                                            <li>Make sure the photo is clear, in sharp focus, free from red-eye and free from any reflection/glare from glasses.                                        </li>
                                                            <li>Do not use a “selfie”.                                                                                                                                  </li>
                                                            <li>Do not include any other persons in your photo.                                                                                                         </li>
                                                            <li>Do not use images of buildings, pets, cars, etc as your headshot.                                                                                       </li>
                                                            <li>Do not use filters and stickers.                                                                                                                        </li>
                                                        </ul>
                                                    </li>
                                                    <li>Attire and Appearance:
                                                        <ul>
                                                            <li>Maintain good and appropriate posture; back straight, shoulders back, and remain relaxed (as if you are having an in-person interview).                 </li>
                                                            <li>Wear professional or business casual attire (scrubs or lab coats are allowed).                                                                          </li>
                                                            <li>Do not wear hats, sunglasses, tank tops or sleeveless shirts.                                                                                           </li>
                                                        </ul>
                                                    </li>
                                                    <li>Logos:
                                                        <ul>
                                                            <li>Do not use the old blue Care Access logo.                                                                                                               </li>
                                                            <li>No other logos or brands should be in your headshot photo. Please be aware of  branding on clothing and items in the background of the frame.           </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <h5>Recommended</h5>
                                                <ul>
                                                    <li>Use a simple background.</li>
                                                    <li>Your face should take up 60% or more of the frame.</li>
                                                    <li>Image dimensions should be 180x180 pixels.</li>
                                                </ul>
                                                <p className="mt-5">
                                                    <mark><strong>Note</strong></mark>: Inappropriate profile photos/headshots will be removed without notice.
                                                </p>
                                            </div>
                                        </div>
                                        <p>
                                            To update your profile photo click on your photo above or <a href={"https://myaccount.google.com/profile/photo/edit?authuser=" + currentUser.getEmail() } target="_blank">simply click here</a> to go directly to your profile page and change it there. Once updated, please refresh this page to make use of the new photo.
                                        </p>
                                        <h4>Email Signature</h4>
                                        <ul>
                                            <li>
                                                <h5>Headshot Photo</h5>
                                                By default, this tool will grab your Gmail profile photo. To remove your photo, simply uncheck the “Use photo in signature” and your photo will be removed.
                                            </li>
                                            <li>
                                                <h5>Full Name (Display Name)</h5>
                                                You have the option to change the way your name shows up when someone receives an email from you. If there are no changes or corrections to your name, you can leave it as is.
                                            </li>
                                            <li>
                                                <h5>Role Title at Care Access</h5>
                                                Please make sure you use the exact role title that has been approved by your supervisor.
                                            </li>
                                            <li>
                                                <h5>Phone Number</h5>
                                                You may leave this blank or use your phone number as assigned by the IT team. When entering your phone number, please use the format "(area code)-xxx-xxxx".
                                            </li>
                                            <li>
                                                <h5>Credentials</h5>
                                                This field is optional and not required. If you do have credentials that you wish to use, the company has a policy where one can only use credentials acquired within the US. Degrees or titles acquired outside of the states should not be included in this field.<br/>
                                                If you fill in this field, your Full Name (Display Name) will have the credentials appended automatically. (.e.g. "John Doe" would become "John Doe, PhD")
                                            </li>
                                            <li>
                                                <h5>Email Sign-off</h5>
                                                This field is optional as well. It is included in this tool simply to make it easier for you to add your favorite sign-off (e.g. Best, Regards, etc.) right before your signature.
                                            </li>
                                        </ul>
                                        <div className="alert alert-info mt-5" role="alert">
                                            Thank you for your cooperation and please feel free to reach out to us at <a href="mailto:marketing@careaccess.com">
                                            marketing@careaccess.com
                                        </a>   if you have any questions.
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                            Use the tool
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="successModal" tabIndex="-1" role="dialog"
                             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog modal-xl" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLongTitle">Success!</h5>
                                        <strong className="pt-4 pl-3">(Your signature was copied to your clipboard!)</strong>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body text-center">
                                        <p className="p-3">
                                            To use your new signature in the <strong>Outlook on the Web</strong>, please visit <a href="https://outlook.office.com/mail/options/mail/messageContent" target="_blank">https://outlook.office.com/mail/options/mail/messageContent</a> in your browser and follow the steps shown in the gif image below.
                                        </p>
                                        <img src="/tutorial.gif" className="tutorial-image" />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ):(
                    <Loader text="retrieving user data..."></Loader>
                )
            }
        </>

    )
}

export default Home;