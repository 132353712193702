import React from "react";


const AppTemplate = ({ children }) => {

    return (
        <div className="c-app">
            {children}
        </div>
    )
}

export default AppTemplate;