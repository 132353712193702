import React, { useEffect, useState } from "react";
import { loadGoogleScript } from "../../lib/LoadGAPI";
import { config } from "./gapi-config.js";

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
    const [authUser, setAuthUser] = useState({
        'user': null,
        'checked': false
    });
    const [gapi, setGapi] = useState(null)
    const [googleAuth, setGoogleAuth] = useState(null)
    const [userData, setUserData] = useState({})

    const onSuccess = (googleUser) => {
        setAuthUser({user: googleUser, checked: true})
        if (document.location.pathname === '/signin') {
            document.location.href = '/'
        }
    };

    const onFailure = () => {
        setAuthUser({user: null, checked: true})
    }

    useEffect(() => {

        const renderSigninButton = () => {
            let job = setInterval(() => {
                if (authUser.user !== null) {
                    clearInterval(job)
                }
                if (document.getElementById('google-sign-in')) {
                    gapi.signin2.render('google-sign-in', {
                        'scope': config.scope,
                        'width': 240,
                        'height': 40,
                        'longtitle': true,
                        'theme': 'dark',
                        'onsuccess': onSuccess,
                        'onfailure': onFailure
                    });
                    clearInterval(job);
                }
            }, 300);
        }

        const updateSigninStatus = () => {
            const user = googleAuth.currentUser.get();
            const isAuthorized = user.hasGrantedScopes(config.scope);
            if (isAuthorized) {
                onSuccess(user.getBasicProfile())
            } else {
                setAuthUser({user: null, checked: true})
                renderSigninButton()
            }
        }

        if (!gapi) {
            window.onGoogleScriptLoad = () => {
                const _gapi = window.gapi;
                setGapi(_gapi);

                _gapi.load('client:auth2', () => {
                    _gapi.client.init({
                        client_id: config.googleClientId,
                        apiKey: config.apiKey,
                        scope: config.scope
                    }).then(function () {
                        const _googleAuth = _gapi.auth2.getAuthInstance()
                        setGoogleAuth(_googleAuth)
                    })
                });
            }
        }

        if (gapi && googleAuth) {
            updateSigninStatus()
            googleAuth.isSignedIn.listen(updateSigninStatus)
        }

        loadGoogleScript();

    }, [gapi, googleAuth]);

    return (
        <AuthContext.Provider
            value={{
                authUser, userData, setUserData
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};