export const GetCareSignature = (name, role, phone, fax, email, timezone, meetingLink, credentials, photo, signoff) => {
    let htmlSignature = '<br>'
    let displayName = "<span alt='fullname'>" + name + "</span>"
    let displayCredentials = ''
    if (credentials && credentials !== '') {
        displayCredentials = ", <span  alt='credentials'>" + credentials + "</span>"
    }

    let meetingLinkText = "Schedule a meeting";
    if (timezone) {
        meetingLinkText += " (" + timezone +")";
    }

    // include signoff if there
    if (signoff && signoff !== '') {
        htmlSignature = htmlSignature + '<p><span alt="signoff">' + signoff + '</span></p>'
    }

    // include display name
    htmlSignature += '<p style="margin-top:12px;margin-bottom:0;color:#009991;font-size:16px;font-weight:bold;font-family:Calibri,sans-serif;">' + displayName + displayCredentials +'</p>'

    // include role/position
    htmlSignature += '<p style="margin-top:3px;margin-bottom:12px;color:rgb(25,104,107) !important;font-size:14.2px;font-family:Calibri,sans-serif;"><span alt="role">' + role + '</span></p>'

    // Start the block below the role
    htmlSignature = htmlSignature +
        "<table style='direction:ltr;border:none;border-collapse:collapse;width:100%;max-width:400px;'>" +
        "<tbody>" +
        "<tr>"
    if (photo && photo !== '') {
        htmlSignature = htmlSignature +
            "<td width='83px' style='border-top:1px solid #d9d9d9;vertical-align:top;padding:10px 5px 5px 0 !important;overflow:hidden;overflow-wrap:break-word;'>" +
            "<img src='" + photo + "' alt='" + {name} +"' width='83' height='83' style='margin-top:0;margin-left:0;'/>" +
            "</td>"
    }

    // Start the cell on the right of the photo
    htmlSignature += "<td style='border-top:1px solid #d9d9d9;vertical-align:top;padding:10px 5px 5px 5px !important;overflow:hidden;overflow-wrap:break-word'>";

    // add the phone if there is one
    if (phone && phone !== '') {
        htmlSignature = htmlSignature +
            "<p dir='ltr' style='margin:0;line-height:20px;'>" +
                '<a style="text-decoration:none !important;display:inline-block;white-space:nowrap;" href="tel:' + phone + '">' +
                    '<img src="https://careaccess.com/assets/images/email-signature/phone.png" style="height:16px;width:16px;vertical-align:middle;margin-right:4px;"/>' +
                    '<span style="color:rgb(25,104,107) !important;cursor:pointer;font-size:14.2px;font-family:Calibri,sans-serif;" alt="phone">' + phone + '</span>' +
                '</a>' +
            "</p>"
    }

    // Add fax if there is one
    if (fax && fax !== '') {
        htmlSignature = htmlSignature +
        "<p dir='ltr' style='margin:0;line-height:20px;'>" +
            '<img src="https://careaccess.com/assets/images/email-signature/printer.png" style="height:16px;width:16px;vertical-align:middle;margin-right:4px;"/>' +
            '<span style="color:rgb(25,104,107) !important;cursor:pointer;font-size:14.2px;font-family:Calibri,sans-serif;" alt="fax">' + fax + '</span>' +
        "</p>"
    }

    // Display timezone/location on its own if there is no meeting link
    if (timezone && !meetingLink) {
        htmlSignature = htmlSignature +
            "<p dir='ltr' style='margin:0;line-height:20px;'>" +
                '<img src="https://careaccess.com/assets/images/email-signature/calendar.png" style="height:16px;width:16px;vertical-align:middle;margin-right:4px;"/>' +
                '<span style="color:rgb(25,104,107) !important;cursor:pointer;font-size:14.2px;font-family:Calibri,sans-serif;" alt="timezone">' + timezone + '</span>' +
            "</p>"
    }

    // Include meeting link
    if (meetingLink) {
        htmlSignature = htmlSignature +
            "<p dir='ltr' style='margin:0;line-height:20px;'>" +
                '<a style="text-decoration:none !important;display:inline-block;white-space:nowrap;" href="' + meetingLink + '" alt="schedule">' +
                    '<img src="https://careaccess.com/assets/images/email-signature/calendar.png" style="height:16px;width:16px;vertical-align:middle;margin-right:4px;"/>' +
                    '<span style="color:rgb(25,104,107) !important;cursor:pointer;font-size:14.2px;font-family:Calibri,sans-serif;">' + meetingLinkText + "</span>" +
                "</a>" +
            "</p>"
    }

    // Include the email address
    htmlSignature = htmlSignature +
        "<p dir='ltr' style='margin:0;line-height:20px;'>" +
            '<a style="text-decoration:none !important;display:inline-block;white-space:nowrap;" href="mailto:' + email + '" alt="email">' +
                '<img src="https://careaccess.com/assets/images/email-signature/email.png" style="height:16px;width:16px;vertical-align:middle;margin-right:4px;"/>' +
                '<span style="color:rgb(25,104,107) !important;cursor:pointer;font-size:14.2px;font-family:Calibri,sans-serif;">' + email + "</span>" +
            "</a>" +
        "</p>"

    // Include website simply to fill in the space if needed
    if (!fax || !phone ||  (!meetingLink && !timezone)) {
        htmlSignature = htmlSignature +
            "<p dir='ltr' style='margin:0;line-height:20px;'>" +
                '<a style="text-decoration:none !important;display:inline-block;white-space:nowrap;" href="https://www.careaccess.com" target="_blank" alt="Care Access Website">' +
                    '<img src="https://careaccess.com/assets/images/email-signature/laptop.png" style="height:16px;width:16px;vertical-align:middle;margin-right:4px;"/>' +
                    '<span style="color:rgb(25,104,107) !important;cursor:pointer;font-size:14.2px;font-family:Calibri,sans-serif;">www.careaccess.com</span>' +
                "</a>" +
            "</p>"
    }

    // Include a quote simply to make up the 4 lines
    if ((+Boolean(fax) + + +Boolean(phone) + +Boolean(timezone) + +Boolean(meetingLink)) < 2) {
        htmlSignature = htmlSignature +
            "<p dir='ltr' style='margin:0;line-height:20px;'>" +
                "<span style='color:rgb(25,104,107) !important;cursor:pointer;font-size:14.2px;font-family:Calibri,sans-serif;'>~ Accelerating the Future of Medicine ~</span>" +
            "</p>"
    }

    htmlSignature = htmlSignature +

        "</td>" +
        "</tr>" +
        "</tbody>" +
        "</table>" +
        "<table style='direction:ltr;border:none;border-collapse:collapse;width:100%;max-width:400px'>" +
        "<tbody>" +
        "<tr>" +
        "<td style='vertical-align:middle;background-color:#05474a;padding:0 0.25rem;overflow:hidden;overflow-wrap:break-word'>" +
        "<table style='width:98%'>" +
        "<tbody>" +
        "<tr>" +
        "<td>" +
        "<p dir='ltr' style='line-height:1.2;margin:0'>" +
        "<a style='text-decoration:none !important;' href='https://careaccess.com'>" +
        "<span style='font-size:24px;font-family:Palatino,serif;background-color:transparent;vertical-align:middle;white-space:pre-wrap'> </span>" +
        "<span style='font-size:14.2px;font-family:Palatino,serif;color:rgb(171,201,186);background-color:transparent;font-feature-settings:normal;font-variant-numeric:normal;font-variant-east-asian:normal;vertical-align:middle;white-space:pre-wrap'>CareAccess.com</span>" +
        "</a>" +
        "<span style='font-size:14.2px;font-family:Palatino,serif;color:rgb(171,201,186);background-color:transparent;font-feature-settings:normal;font-variant-numeric:normal;font-variant-east-asian:normal;vertical-align:baseline;white-space:pre-wrap'>&nbsp; &nbsp; &nbsp; &nbsp; </span>" +
        "</p>" +
        "</td>" +
        "<td style='text-align: right;'>" +
        "<p dir='ltr' style='line-height:1.2;margin:0'>" +
        "<a style='text-decoration:none !important;' href='https://www.linkedin.com/company/careaccess'>" +
        "<span style='font-size:9.3px;font-family:Verdana;color:rgb(171,201,186);background-color:transparent;font-feature-settings:normal;font-variant-numeric:normal;font-variant-east-asian:normal;vertical-align:baseline;white-space:pre-wrap'>" +
        "LinkedIn" +
        "</span>" +
        "</a>" +
        "<span style='font-size:9.3px;font-family:Verdana;color:rgb(171,201,186);background-color:transparent;font-feature-settings:normal;font-variant-numeric:normal;font-variant-east-asian:normal;vertical-align:baseline;white-space:pre-wrap'> | </span>" +
        "<a style='text-decoration:none !important;' href='https://www.facebook.com/CareAccessRes/'>" +
        "<span style='font-size:9.3px;font-family:Verdana;color:rgb(171,201,186);background-color:transparent;font-feature-settings:normal;font-variant-numeric:normal;font-variant-east-asian:normal;vertical-align:baseline;white-space:pre-wrap'>" +
        "Facebook" +
        "</span>" +
        "</a>" +
        "<span style='font-size:9.3px;font-family:Verdana;color:rgb(171,201,186);background-color:transparent;font-feature-settings:normal;font-variant-numeric:normal;font-variant-east-asian:normal;vertical-align:baseline;white-space:pre-wrap'> | </span>" +
        "<a style='text-decoration:none !important;' href='https://www.youtube.com/channel/UC_RFAvqf86t_I8lgczCKjjA'>" +
        "<span style='font-size:9.3px;font-family:Verdana;color:rgb(171,201,186);background-color:transparent;font-feature-settings:normal;font-variant-numeric:normal;font-variant-east-asian:normal;vertical-align:baseline;white-space:pre-wrap'>" +
        "YouTube" +
        "</span>" +
        "</a>" +
        "<span style='font-size:9.3px;font-family:Verdana;color:rgb(171,201,186);background-color:transparent;font-feature-settings:normal;font-variant-numeric:normal;font-variant-east-asian:normal;vertical-align:baseline;white-space:pre-wrap'> | </span>" +
        "<a style='text-decoration:none !important;' href='https://twitter.com/careaccessres?lang=en'>" +
        "<span style='font-size:9.3px;font-family:Verdana;color:rgb(171,201,186);background-color:transparent;font-feature-settings:normal;font-variant-numeric:normal;font-variant-east-asian:normal;vertical-align:baseline;white-space:pre-wrap'>" +
        "Twitter" +
        "</span>" +
        "</a>" +
        "</p>" +
        "</td>" +
        "</tr>" +
        "</tbody>" +
        "</table>" +
        "</td>" +
        "</tr>" +
        "<tr style='height:0'>" +
        "<td style='vertical-align:top;overflow:hidden;overflow-wrap:break-word'>" +
        "<p dir='ltr' style='margin:0;line-height:1.5'>" +
        "<a style='text-decoration:none !important;' href='https://careaccess.com'>" +
        "<span style='font-size:14.2px;font-family:Calibri,sans-serif;color:rgb(5,71,74);background-color:transparent;font-feature-settings:normal;font-variant-numeric:normal;font-variant-east-asian:normal;vertical-align:baseline;white-space:pre-wrap'>" +
        "<span style='border:none;display:block;overflow:hidden;width:100%;height:48px'>" +
        "<img src='https://careaccess.com/email-care-logo.png' style='display:block;margin:0 auto' width='364' height='48'  />" +
        "</span>" +
        "</span>" +
        "</a>" +
        "</p>" +
        "</td>" +
        "</tr>" +
        "</tbody>" +
        "</table>"
    console.log(htmlSignature)
    return htmlSignature
}