import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/GAPI';

import PublicRouter from './components/routers/PublicRouter';
import AuthRouter from './components/routers/AuthRouter';

import PublicTemplate from './components/templates/PublicTemplate';
import AppTemplate from './components/templates/AppTemplate';

import SignIn from './pages/SignIn';
import Home from './pages/Home';
import NotFound from './pages/NotFound';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>

          <Route
            path=""
            element={
              <AuthRouter template={AppTemplate} title="Signature Tool" component={Home} />
            }
          />

          <Route
            path="/signin"
            element={
              <PublicRouter template={PublicTemplate} title="Sign In" component={SignIn} />
            }
          />

          <Route
            path="*"
            element={
              <PublicRouter template={PublicTemplate} title="Page Not Found" component={NotFound} />
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
