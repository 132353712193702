import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../GAPI";
import PublicTemplate from "../../templates/PublicTemplate";
import Loader from "../../Loader";

const AuthRouter = ({
  component: Component,
  template: Template,
  title: Title,
  ...rest
}) => {
  document.title = Title;

  const { authUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {

    setTimeout(() => {
      if (authUser && authUser.checked) {
        if (authUser.user) {
          return (
            <Template {...rest}>
              <Component />
            </Template>
          );
        }  else {
          // User is not logged in, perform redirection
          navigate("/signin");
        }
      }
    }, 100); // Delay the redirection by 500 milliseconds
  }, [authUser, navigate]);

  if (authUser && authUser.user) {
    return (
      <Template {...rest}>
        <Component />
      </Template>
    );
  }

  // Else return loading
  return (
    <PublicTemplate>
      <Loader size="5x" text="Loading..." />
    </PublicTemplate>
  );
};
export default AuthRouter;
