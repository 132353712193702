import React from 'react';

const PublicRouter = ({ component: Component, template: Template, title: Title, ...rest }) => {
  document.title = Title;

  return (
    <Component template={Template} {...rest} />
  );
};

export default PublicRouter;
