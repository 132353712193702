export const loadGoogleScript = () => {

    // Loads the Google JavaScript Library
    (function () {
        const id = 'gapi-js'
        if (document.getElementById(id)) { return; }

        const src = 'https://apis.google.com/js/platform.js'
        const firstJs = document.getElementsByTagName('script')[0]
        const js = document.createElement('script'); // (Ref. 4)
        js.id = id;
        js.src = src;
        js.onload = window.onGoogleScriptLoad; // (Ref. 5)
        firstJs.parentNode.insertBefore(js, firstJs);
    }());
}